import React from "react";
import { Styled } from "./styles";
import { DevBar } from "./components/DevBar";

export const DevTools: React.FC = () => {
  console.log("devTools");

  const isUseProductionData = Boolean(
    sessionStorage.getItem("isUseProductionData")
  );
  return (
    <>
      {isUseProductionData && (
        <Styled.AlertWrapper
          message="Be careful! You are using a production data!"
          type="error"
          showIcon
        />
      )}
      <DevBar />
    </>
  );
};

import {
  IMerchantDetail,
  IMerchantValidator,
  Kinds,
  MerchantFormType,
  OriginTypes,
  Statuses,
} from "scenarios/merchant/types";
import * as yup from "yup";
import ObjectSchema, { AnyObject } from "yup/lib/object";

export const countryCode = "GB";

const phoneRegexp = new RegExp(/^[0-9]+$/);
const phoneMaxLen = 15;

const dbaRegexp = new RegExp(/^[-A-Za-z0-9_.();:&=<+,%?’ ]+$/);
const dbaMaxLen = 18;

const bankAccountHolderName = new RegExp(/^[A-Za-z. ]+$/);

const bankAccountNumberRegexp = new RegExp(/^[A-Z0-9]+$/);
const bankAccountNumberMinLen = 8;

const sortCodeRegexp = new RegExp(/^[A-Z0-9]+$/);
const sortCodeMinLen = 6;

export const FIELD_TAB_MAPPING: Record<string, string> = {
  "merchantName": "details",
  "merchantNumber": "details",
  "dba": "details",
  "kind": "details",
  "status": "details",
  "mcc": "details",
  "feePercent": "details",
  "partner.id": "details",
  "country": "details",
  "timeZone": "details",
  "currency": "details",
  "originType": "details",
  "fsrvExternalId": "integrationDetails",
  "vatID": "integrationDetails",
  "onboardingDetailsTotalSales": "integrationDetails",
  "onboardingDetailsProductServiceSold": "integrationDetails",
  "cdxExternalId": "integrationDetails",
  "cctExternalId": "integrationDetails",
  "tidPrefix": "integrationDetails",
  "mid": "integrationDetails",
  "needExportData": "integrationDetails",
  "registrationAddressData": "registrationAddress",
  "officeAddressData": "officeAddress",
  "merchantContactData": "contacts",
  "settlementDetailsData": "settlement",
  "merchantPrincipalData": "principals"
}

export const merchantSchema = yup.object<
  Partial<Record<keyof IMerchantDetail, yup.AnySchema>>
>({
  merchantName: yup.string().required("Merchant Name is required"),
  merchantNumber: yup.string().nullable(),
  dba: yup
    .string()
    .required("DBA is required")
    .matches(dbaRegexp, "Wrong character input")
    .max(dbaMaxLen, "DBA can be max 18 char long"),
  kind: yup.string().oneOf(Kinds).required("Kind is required"),
  status: yup.string().oneOf(Statuses).required("Status is required"),
  country: yup.string().required("Country is required"),
  currency: yup.string().required("Currency is required"),
  vatID: yup.string().max(255).nullable(),
  onboardingDetailsTotalSales: yup.number().min(0).nullable(),
  onboardingDetailsProductServiceSold: yup.string().min(0).max(250).nullable(),
  cctExternalId: yup.string().nullable(),
  cdxExternalId: yup.string().nullable(),
  fsrvExternalId: yup.string().required("Fiserv External ID is required"),
  tidPrefix: yup.string().nullable(),
  mid: yup.string().nullable(),
  settlementDetailsData: yup.object().when("country", {
    is: countryCode,
    then: yup
      .object()
      .shape({
        sortCode: yup
          .string()
          .matches(sortCodeRegexp, "Value format is wrong")
          .min(sortCodeMinLen, `Value must be equal or more than ${sortCodeMinLen} char long`
          ),
        bankAccountNumber: yup
          .string()
          .matches(bankAccountNumberRegexp, "Value should have digits and Capital letters only")
          .min(bankAccountNumberMinLen, `Value must be equal or more than ${bankAccountNumberMinLen} char long`),
        bankAccountHolderName: yup
          .string()
          .matches(bankAccountHolderName, "Value should have digits, letters and symbols '.', ' '"),
      })
      .nullable(),
    otherwise: yup
      .object()
      .shape({
        sortCode: yup.string().nullable(),
        bankAccountNumber: yup.string().nullable(),
        bankAccountHolderName: yup
          .string(),
      })
      .nullable(),
  }),
  mcc: yup.string().required("MCC is required"),
  registrationAddressData: yup
    .object()
    .shape({
      addressLine1: yup.string().required("Address Line 1 is required"),
      addressLine2: yup.string(),
      country: yup.string().required("Country is required"),
      county: yup.string().required("County is required"),
      city: yup.string().required("City is required"),
      state: yup.string().nullable(),
      postCode: yup.string().required("Post Code is required"),
      postTown: yup.string().nullable(),
    })
    .required("Registration Address is required"),
  officeAddressData: yup.object().shape({
    addressLine1: yup.string(),
    addressLine2: yup.string(),
    country: yup.string(),
    county: yup.string(),
    city: yup.string(),
    state: yup.string().nullable(),
    postCode: yup.string(),
    postTown: yup.string().nullable(),
  }),
  timeZone: yup.string().required("Timezone is required"),
  feePercent: yup
    .number()
    .typeError("Fee Percent must be number")
    .min(0)
    .max(100)
    .required("Fee Percent is required"),
  merchantContactData: yup
    .object()
    .shape({
      email: yup.string().email().required("Email is required"),
      siteUrl: yup
        .string()
        .url("Invalid Site Url format (Example: https://dapio.com)")
        .required("Site Url is required"),
      phoneNumber: yup
        .string()
        .required("Primary Phone Number is required")
        .matches(phoneRegexp, "Primary Phone Number must be numbers only")
        .max(
          phoneMaxLen,
          `Phone number must be less than ${phoneMaxLen + 1} char long`
        ),
      primaryContactPhoneNumber: yup
        .string()
        .required("Primary Phone Number is required")
        .matches(phoneRegexp, "Primary Phone Number must be numbers only")
        .max(
          phoneMaxLen,
          `Primary Phone number must be less than ${phoneMaxLen + 1} char long`
        ),
      primaryContactFirstName: yup
        .string()
        .required("Primary First Name is required"),
      primaryContactLastName: yup
        .string()
        .required("Primary Last Name is required"),
    })
    .required("Contact Details is required"),
  partner: yup.object().shape({
    id: yup.number(),
  }),
  needExportData: yup
    .object()
    .shape({
      toCct: yup.bool().required("CCT export is required"),
    })
    .required("CCT export is required"),
  merchantPrincipalData: yup
    .object()
    .shape({
      principals: yup
        .array()
        .of(
          yup.object().shape({
            firstName: yup.string().required("First Name is required"),
            lastName: yup.string().required("Last Name is required"),
            dateOfBirth: yup.date().required("DOB is required"),
            addressLine1: yup.string().required("Address Line 1 is required"),
            addressLine2: yup.string().nullable(),
            city: yup.string().required("City is required"),
            country: yup.string().required("Country is required"),
            postCode: yup.string().required("Post Code is required"),
            phoneNumber: yup
              .string()
              .required("Phone Number is required")
              .matches(phoneRegexp, "Phone Number must be numbers only")
              .max(
                phoneMaxLen,
                `Phone number must be less than ${phoneMaxLen + 1} char long`
              ),
            email: yup.string().email().required("Email is required"),
            ownership: yup.number().required("Ownership is required"),
          })
        )
        .min(1)
        .required("At least 1 principal is required"),
    })
    .required("Principal Details is required"),
  originType: yup
    .string()
    .default("EXTERNAL")
    .oneOf(OriginTypes)
    .required("Origin Type is required"),
});

export const yupValidator = <T extends AnyObject>(
  schema: ObjectSchema<T>,
  getFieldsValue: () => T,
  updateButtonState: React.Dispatch<React.SetStateAction<boolean>>,
  switchTab: React.Dispatch<React.SetStateAction<string>>,
  formType: MerchantFormType
): IMerchantValidator => ({
  async validator({ field }: any) {
    const values = getFieldsValue();
    if (formType === "create") {
      const isValid = await schema.isValid(values);
      updateButtonState(isValid);
    }

    try {
      await schema.validateAt(field, values);
    } catch (err: any) {
      if (typeof field === "string") {
        const fieldNames = field.split(".");
        if (fieldNames && fieldNames.length && typeof FIELD_TAB_MAPPING[fieldNames[0]] === "string") {
          let tabKey = FIELD_TAB_MAPPING[fieldNames[0]];
          if (fieldNames.length === 4) {
            tabKey += `${fieldNames[2]}`
          }
          switchTab(tabKey);
        }
      }
      throw err;
    }
    
  },
});

import { IPartnerResponse } from "../../data-layer/partner/types/partnerTypes";
import {
  IDictionaryMccResponse,
  IDictionaryResponse,
} from "../../data-layer/dictionary/types/IDictionary";
import { ICountries } from "shared/api/dictionary/types";
import { FormInstance } from "antd";
import { AnySchema } from "yup";
import { OptionalObjectSchema } from "yup/lib/object";

export const OriginTypes = ["EXTERNAL", "INTERNAL"];
export type OriginType = (typeof OriginTypes)[number];

export const Kinds = ["COMPANY", "SOLE_TRADER"];
export type Kind = (typeof Kinds)[number];

export const Statuses = ["NEW", "ACTIVATED", "SUSPENDED"];
export type Status = (typeof Statuses)[number];

export interface ISettlementDetails {
  sortCode: string;
  bankAccountNumber: string;
  bankAccountHolderName: string;
}

export interface IAddress {
  addressLine1: string;
  addressLine2: string;
  country: string;
  county: string;
  city: string;
  state: string;
  postCode: string;
  postTown: string;
}

export interface IContactData {
  email: string;
  siteUrl: string;
  phoneNumber: string;
  primaryContactPhoneNumber: string;
  primaryContactFirstName: string;
  primaryContactLastName: string;
}

export interface IPrincipalData {
  firstName: string;
  lastName: string;
  dateOfBirth: Date;
  addressLine1: string;
  addressLine2: string;
  city: string;
  country: string;
  postCode: string;
  phoneNumber: string;
  email: string;
  ownership: number;
}

export interface IExportData {
  toCct: boolean;
}

export interface IPartner {
  id: number;
  name: string;
  feePercent: number;
}

interface IMerchantFiservData {
  status: "IN_PROGRESS" | "APPROVED" | "REJECTED";
  resultDeclineReasonCode: string;
  resultGNFStatus: string;
  resultRNFStatus: string;
  resultMCMatchStatus: string;
  resultVMASStatus: string;
}

type FeeType = "BLENDED_FEE" | "DETAILED_FEE";


interface IMerchantPricingModel {
  enabled: boolean;
  feeTxFix: number;
  feeTxPercentage: number;
  feeType: FeeType;
}

export interface IMerchant {
  id?: string;
  merchantName: string;
  vatID: string;
  onboardingDetailsTotalSales: number;
  onboardingDetailsProductServiceSold: string;
  dba: string;
  kind: Kind;
  mcc: string;
  timeZone: string;
  country: string;
  currency: string;
  feePercent: number;
  originType: OriginType;
  status: Status;
  merchantNumber?: string;
  cctExternalId?: string;
  cdxExternalId?: string;
  fsrvExternalId: string;
  tidPrefix?: string;
  mid?: string;
}

export interface IMerchantDetail extends IMerchant {
  settlementDetailsData: ISettlementDetails | null;
  registrationAddressData: IAddress;
  officeAddressData?: IAddress;
  merchantContactData: IContactData;
  merchantPrincipalData: {
    principals: IPrincipalData[];
  };
  partner?: IPartner;
  needExportData: IExportData;
  merchantFiservData?: IMerchantFiservData;
  pmCurrency?: string;
  pmInStore?: IMerchantPricingModel;
  pmOnline?: IMerchantPricingModel;
  pmOnlineGlobal?: IMerchantPricingModel;
}

export interface IMerchantUpdateDetail extends IMerchantDetail {
  id: string;
}

export type MerchantCreateError = {
  error: string;
  details: Record<string, string>;
};
export type MerchantCreateErrorResponse = {
  status?: number;
  data: MerchantCreateError;
};

export type MerchantUpdateError = {
  error: string;
  details: Record<string, string>;
};
export type MerchantUpdateErrorResponse = {
  status?: number;
  data: MerchantUpdateError;
};

export type MerchantFormType = "create" | "edit";

export interface IMerchantForm {
  type: MerchantFormType;
  form: FormInstance<IMerchantDetail>;
  merchantSchema: OptionalObjectSchema<
    Partial<Record<keyof IMerchantDetail, AnySchema>>
  >;
  dictionaryData: IDictionaryResponse;
  dictionaryMccData: IDictionaryMccResponse;
  partners: IPartnerResponse[];
  initialData?: IMerchantDetail;
  onProcessMerchant: (fields: IMerchantDetail) => void;
}

export interface IMerchantValidator {
  validator({ field }: any): Promise<void>;
}

export type DefaultCountry = {
  value: number;
  label: string;
};

interface BaseFormPart {
  validator: IMerchantValidator;
  initialData?: IMerchantDetail;
}

export interface IMerchantDetailsFormPart extends BaseFormPart {
  countries: ICountries[];
  timezones: string[];
  currencies: string[];
  mccList: string[];
  partners: IPartnerResponse[];
}

export interface IMerchantIntegrationDetailsFormPart extends BaseFormPart {}

export interface IMerchantSettlementsFormPart extends Omit<BaseFormPart, "initialData"> {
  initialData?: ISettlementDetails | null;
}

export interface IMerchantRegistrationDetailsFormPart extends Omit<BaseFormPart, "initialData"> {
  countries: ICountries[];
  type: MerchantFormType;
  isAddressSameAsRegistration: boolean;
  setSameRegistrationAddress: Function;
  initialData?: IAddress;
}

export interface IMerchantOfficeDetailsFormPart extends Omit<BaseFormPart, "initialData"> {
  countries: ICountries[];
  isAddressSameAsRegistration: boolean;
  initialData?: IAddress;
}

export interface IMerchantContactsFormPart extends Omit<BaseFormPart, "initialData"> {
  initialData?: IContactData;
}

export interface IMerchantPrincipalDetailsFormPart extends Omit<BaseFormPart, "initialData"> {
  countries: ICountries[];
  principalData: IPrincipalData;
  index: number;
}

export interface IMerchantPricingModelsFormPart extends BaseFormPart {
  pmCurrency: "GBP" | "EUR" | "USD";
  pmInStore: Omit<IMerchantPricingModel, "feeType">;
  pmOnline: Omit<IMerchantPricingModel, "feeType">;
  pmOnlineGlobal: Omit<IMerchantPricingModel, "feeTxPercentage" | "feeTxFix" | "feeType">;
}


import { Form as AntForm, Input, Row, Col } from "antd";
import { IMerchantContactsFormPart } from "./types";
import { PhoneInput } from "../../common/phoneInput";

export const MerchantContactsTab: React.FC<IMerchantContactsFormPart> = ({
  validator,
  initialData,
}) => {
  return (
    <Row gutter={32}>
      <Col span={16}>
        <AntForm.Item
          rules={[validator]}
          name={["merchantContactData", "email"]}
          id={"contactEmail"}
          label={"Email"}
          initialValue={initialData?.email}
        >
          <Input />
        </AntForm.Item>
        <AntForm.Item
          rules={[validator]}
          name={["merchantContactData", "siteUrl"]}
          id={"contactSiteUrl"}
          label={"Site Url (Example: https://dapio.com)"}
          initialValue={initialData?.siteUrl}
        >
          <Input />
        </AntForm.Item>
        <AntForm.Item
          rules={[validator]}
          name={["merchantContactData", "phoneNumber"]}
          id={"contactPhoneNumber"}
          label={"Phone"}
          initialValue={initialData?.phoneNumber}
        >
          <PhoneInput country={"gb"} />
        </AntForm.Item>
        <AntForm.Item
          rules={[validator]}
          name={["merchantContactData", "primaryContactPhoneNumber"]}
          id={"contactPrimaryContactPhoneNumber"}
          label={"Primary Contact Phone"}
          initialValue={initialData?.primaryContactPhoneNumber}
        >
          <PhoneInput country={"gb"} />
        </AntForm.Item>
        <AntForm.Item
          rules={[validator]}
          name={["merchantContactData", "primaryContactFirstName"]}
          id={"contactPrimaryContactFirstName"}
          label={"Primary Contact First Name"}
          initialValue={initialData?.primaryContactFirstName}
        >
          <Input />
        </AntForm.Item>
        <AntForm.Item
          rules={[validator]}
          name={["merchantContactData", "primaryContactLastName"]}
          id={"contactPrimaryContactLastName"}
          label={"Primary Contact Last Name"}
          initialValue={initialData?.primaryContactLastName}
        >
          <Input />
        </AntForm.Item>
      </Col>
    </Row>
  );
};

import React, { useState } from "react";
import { Switch } from "antd";
import { Styled } from "../styles";

export const DevBar = () => {
  const [checked, setChecked] = useState(
    Boolean(sessionStorage.getItem("isUseProductionData"))
  );

  const onChangeToProductionApiUrl = (value: boolean) => {
    if (value) {
      sessionStorage.setItem("isUseProductionData", "true");
    } else sessionStorage.removeItem("isUseProductionData");
    setChecked(value);
    window.location.reload();
  };

  return (
    <Styled.DevOptionsFooterWrapper>
      <Styled.SwitchWrapper>
        <p>Production data</p>
        <Switch onChange={onChangeToProductionApiUrl} checked={checked} />
      </Styled.SwitchWrapper>
    </Styled.DevOptionsFooterWrapper>
  );
};

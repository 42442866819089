import { Form as AntForm, Input, Row, Col } from "antd";
import { IMerchantSettlementsFormPart } from "./types";

export const MerchantSettlementsTab: React.FC<IMerchantSettlementsFormPart> = ({
  validator,
  initialData,
}) => {
  return (
    <Row gutter={24}>
      <Col span={16}>
        <AntForm.Item
            rules={[validator]}
            name={["settlementDetailsData", "bankAccountHolderName"]}
            label={"Bank Account Holder Name"}
            initialValue={
              initialData?.bankAccountHolderName
            }
        >
          <Input />
        </AntForm.Item>
        <AntForm.Item
          rules={[validator]}
          name={["settlementDetailsData", "sortCode"]}
          label={"Sort Code / BIC / SWIFT"}
          initialValue={initialData?.sortCode}
        >
          <Input />
        </AntForm.Item>
        <AntForm.Item
          rules={[validator]}
          name={["settlementDetailsData", "bankAccountNumber"]}
          label={"Bank Account Number / IBAN"}
          initialValue={initialData?.bankAccountNumber}
        >
          <Input />
        </AntForm.Item>

      </Col>
    </Row>
  );
};

import React, { useEffect, useMemo, useState } from "react";
import {MenuProps, Typography} from "antd";
import { Link, useLocation } from "react-router-dom";
import { ReactComponent as OnboardingIcon } from "assets/onboardingMenuIcon.svg";
import { ReactComponent as PartnersIcon } from "assets/Partners.svg";
import { ReactComponent as SettingsIcon } from "assets/Settings.svg";
import { ReactComponent as Payout } from "assets/Payouts.svg";
import { ReactComponent as AdminIcon } from "assets/admin.svg";
import { ReactComponent as UsersIcon } from "assets/Users.svg";
import { ReactComponent as MonitoringIcon } from "assets/chart.svg";
import { ReactComponent as CompanyIcon } from "assets/Company.svg";
import {
  ROLES,
  useAuthorization,
} from "../../providers/authorization/Authorization";
import { Styled } from "./style";
import { Logout } from "../logout/Logout";

import { EmailTestModal } from "../../scenarios/EmailTest/EmailTestModal";
import { useEmailTest } from "../../data-layer/emailTest/hooks/useEmailTest";


interface IMenuItem {
  key: string;
  path?: string;
  icon?: React.ReactNode;
  label: string | React.ReactNode;
  children?: IMenuItem[];
  accessroles?: ROLES[];
}

type MenuItem = Required<MenuProps>["items"][number];

const menuItems: IMenuItem[] = [
  {
    key: "partners",
    path: "/dashboard/partners",
    icon: <PartnersIcon />,
    label: <Link to={`partners`}>{"Partners"}</Link>,
    accessroles: [
      ROLES.ADMIN,
      ROLES.COMPLIANCE_OFFICER,
      ROLES.EMPLOYEE,
      ROLES.MERCHANT,
      ROLES.PARTNER,
    ],
  },
  {
    key: "onboardings",
    path: "/dashboard/onboardings",
    icon: <OnboardingIcon />,
    label: <Link to={`onboardings`}>{"Onboardings"}</Link>,
    accessroles: [
      ROLES.ADMIN,
      ROLES.COMPLIANCE_OFFICER,
      ROLES.EMPLOYEE,
      ROLES.MERCHANT,
      ROLES.PARTNER,
      ROLES.COMPLIANCE_OFFICER_ASSISTANT,
    ],
  },
  {
    key: "merchants",
    path: "/dashboard/merchants",
    icon: <UsersIcon />,
    label: <Link to={`merchants`}>{"Merchants"}</Link>,
    accessroles: [
      ROLES.ADMIN,
      ROLES.COMPLIANCE_OFFICER,
      ROLES.EMPLOYEE,
      ROLES.MERCHANT,
      ROLES.PARTNER,
    ],
  },
  {
    key: "employees",
    path: "/dashboard/employees",
    icon: <CompanyIcon />,
    label: <Link to={`employees`}>{"Employees"}</Link>,

    accessroles: [
      ROLES.ADMIN,
      ROLES.COMPLIANCE_OFFICER,
      ROLES.EMPLOYEE,
      ROLES.MERCHANT,
      ROLES.PARTNER,
    ],
  },
  {
    key: "transactions",
    path: "/dashboard/transactions",
    icon: <MonitoringIcon />,
    label: <Link to={`transactions`}>{"Transactions"}</Link>,

    accessroles: [
      ROLES.ADMIN,
      ROLES.COMPLIANCE_OFFICER,
      ROLES.EMPLOYEE,
      ROLES.MERCHANT,
      ROLES.PARTNER,
    ],
  },
  {
    key: "overview",
    path: "/dashboard/overview",
    icon: <MonitoringIcon />,
    label: <Link to={`overview`}>{"Overview"}</Link>,

    accessroles: [
      ROLES.ADMIN,
      ROLES.COMPLIANCE_OFFICER,
      ROLES.EMPLOYEE,
      ROLES.MERCHANT,
      ROLES.PARTNER,
    ],
  },
  {
    key: "reports",
    path: "/dashboard/reports",
    icon: <MonitoringIcon />,
    label: <Link to={`reports`}>{"Reports"}</Link>,

    accessroles: [
      ROLES.ADMIN,
      ROLES.COMPLIANCE_OFFICER,
      ROLES.EMPLOYEE,
      ROLES.MERCHANT,
      ROLES.PARTNER,
    ],
  },
  {
    key: "crm",
    path: "/dashboard/crm",
    icon: <MonitoringIcon />,
    label: <Link to={`crm`}>{"CRM"}</Link>,

    accessroles: [
      ROLES.ADMIN,
      ROLES.COMPLIANCE_OFFICER,
      ROLES.EMPLOYEE,
      ROLES.MERCHANT,
      ROLES.PARTNER,
    ],
  },
  {
    key: "payouts",
    icon: <Payout />,
    label: "Payouts",

    accessroles: [
      ROLES.ADMIN,
      ROLES.COMPLIANCE_OFFICER,
      ROLES.EMPLOYEE,
      ROLES.MERCHANT,
      ROLES.PARTNER,
    ],
    children: [
      {
        path: "/dashboard/payouts/merchant",
        key: "payoutMerchant",
        label: <Link to={`payouts/merchant`}>{"Merchants"}</Link>,
      },
      {
        key: "payoutPartner",
        path: "/dashboard/payouts/partner",
        label: <Link to={`payouts/partner`}>{"Partners"}</Link>,
      },
    ],
  },
  {
    key: "users",
    path: "/dashboard/users",
    icon: <UsersIcon />,
    label: <Link to={`users`}>{"Users"}</Link>,
    accessroles: [
      ROLES.ADMIN,
      ROLES.COMPLIANCE_OFFICER,
      ROLES.EMPLOYEE,
      ROLES.MERCHANT,
      ROLES.PARTNER,
    ],
  },
  {
    key: "tools",
    icon: <SettingsIcon />,
    label: "Admin tools",
    accessroles: [ROLES.ADMIN],
    children: [
      {
        key: "dlq",
        path: "/dashboard/tools/dlq",
        label: <Link to={`tools/dlq`}>{"DLQ"}</Link>,
      },
      {
        key: "terminals",
        path: "/dashboard/tools/terminals",
        label: <Link to={`tools/terminals`}>{"Terminals"}</Link>,
      },
      {
        key: "fiserv",
        path: "/dashboard/tools/fiserv",
        label: <Link to={`tools/fiserv`}>{"Fiserv Processes"}</Link>,
      },
      {
        key: "statement-indexes",
        path: "/dashboard/tools/statement-indexes",
        label: (
          <Link to={`tools/statement-indexes`}>{"Statement indexes"}</Link>
        ),
      },
      {
        key: "i18settings",
        path: "/dashboard/tools/i18settings",
        label: <Link to={`tools/i18settings`}>{"i18settings"}</Link>,
      },
      {
        key: "emailTest",
        label: <p>Email test</p>,
      },
    ],
  },
];

const findSelectedKey = (menuItems: any[], pathname: string) => {
  let key = "";
  menuItems.forEach((item) => {
    if (item.hasOwnProperty("children")) {
      const selectedKey = item.children.find((child) =>
        pathname.startsWith(child.path)
      );
      if (selectedKey) key = selectedKey;
    } else {
      const selectedKey = pathname.startsWith(item.path);
      if (selectedKey) key = item;
    }
  });
  return key?.key;
};

const filterNonAccessibleMenuItem = (checkAccess: any) => {
  return menuItems.filter((item) =>
    checkAccess({ allowedRoles: item.accessroles })
  );
};

export const NavigationMenu: React.FC = () => {

  const [visibleEmailTestModal, setVisibleEmailTestModal] =
      useState(false);

  const { onEmailTest } = useEmailTest();

  const { Text } = Typography;

  const location = useLocation();
  const { checkAccess } = useAuthorization();

  const validMenuItems = useMemo(
    () => filterNonAccessibleMenuItem(checkAccess),
    []
  );

  const [selectedKey, setSelectedKey] = useState(
    findSelectedKey(menuItems, location.pathname)
  );

  useEffect(() => {
    setSelectedKey(findSelectedKey(menuItems, location.pathname));
  }, [location]);

  return (
    <>
      <Styled.NavWrapper>
        <Styled.TitleNavWrapper>
          <Link to={"/dashboard/onboardings"}>
            <AdminIcon height={40} width={130} />
            <Styled.NavLabelContainer>
              <Text>
                Admin
                <br />
                Dashboard
              </Text>
            </Styled.NavLabelContainer>
          </Link>
        </Styled.TitleNavWrapper>
        <Styled.MenuWrapper>
          <Styled.Menu
              mode={"inline"}
              style={{ background: "inherit" }}
              items={validMenuItems}
              selectedKeys={[selectedKey]}
              onClick={(e) => {
                if (e.key === "emailTest")
                setVisibleEmailTestModal(true);
              }}
          />
          <Logout />
        </Styled.MenuWrapper>
      </Styled.NavWrapper>
      <EmailTestModal
          visible={visibleEmailTestModal}
          setVisible={setVisibleEmailTestModal}
          onSubmit={onEmailTest}
      />

    </>
  );
};

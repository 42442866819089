import React from "react";
import { Helmet } from "react-helmet";
import { PageHeader, Spin, Form as AntForm, notification } from "antd";
import { Styled } from "./styled";
import { Form } from "./form";
import { history } from "../../routes/AppRouter";
import { useGetMerchantDetailById } from "../../data-layer/merchant/hooks/useGetMerchantDetailById";
import { useDictionary } from "../../data-layer/dictionary/hooks";
import { useDictionaryMcc } from "../../data-layer/dictionary/hooks/useDictionaryMcc";
import { usePartners } from "../../data-layer/partner/hooks/usePartners";
import { useParams } from "react-router-dom";
import {
  IMerchantDetail,
  IMerchantUpdateDetail,
  MerchantUpdateErrorResponse,
} from "./types";
import { merchantSchema } from "../../common/merchantForm";
import { useUpdateMerchant } from "../../data-layer/merchant/hooks/useUpdateMerchant";

export const MerchantEdit: React.FC = () => {
  const { updateMerchant } = useUpdateMerchant();
  const { id } = useParams();

  const merchantId: string = id || "";
  const { data: merchant } = useGetMerchantDetailById(merchantId);
  const { dictionaryData } = useDictionary();
  const { dictionaryMccData } = useDictionaryMcc();
  const partners = usePartners("name=&limit=1000&offset=0", []);

  const [form] = AntForm.useForm<IMerchantDetail>();

  const onUpdateMerchant = async (data: IMerchantDetail) => {
    if (merchant) {
      const updateDetail: IMerchantUpdateDetail = {
        id: merchantId,
        ...data,
      };
      const result = await updateMerchant(updateDetail);
      if ("data" in result) {
        notification.open({
          message: "Request sent successfully",
          type: "success",
        });
        history.back();
      } else if ("error" in result) {
        const error = result.error as MerchantUpdateErrorResponse;
        console.error("ERROR: ", error);
        const keys: string[] = Object.keys(error.data.details);
        const values: string[] = Object.values(error.data.details);

        let description = `${error.data.error}.`;
        keys.forEach(
          (key, index) => (description += ` ${key}: ${values[index]}`)
        );

        notification.open({
          message: "Something went wrong",
          description,
          type: "error",
        });
      }
    }
  };

  return (
    <>
      <Helmet>
        <title>Merchant Edit</title>
      </Helmet>
      <Styled.HeaderWrapper>
        <PageHeader onBack={() => history.back()} title="Merchant Edit" />
      </Styled.HeaderWrapper>
      {merchant && dictionaryData && dictionaryMccData && partners.isSuccess ? (
        <Styled.FormWrapper>
          <Form
            type="edit"
            form={form}
            merchantSchema={merchantSchema}
            dictionaryData={dictionaryData}
            dictionaryMccData={dictionaryMccData}
            partners={partners.data}
            onProcessMerchant={onUpdateMerchant}
            initialData={merchant}
          />
        </Styled.FormWrapper>
      ) : (
        <div
          style={{
            display: "flex",
            height: "90%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Spin size="large" />
        </div>
      )}
    </>
  );
};

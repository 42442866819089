import {
  Form as AntForm,
  Input,
  InputNumber,
  Select,
  DatePicker,
  Row,
  Col,
} from "antd";
import { IMerchantPrincipalDetailsFormPart } from "./types";
import moment from "moment";
import { PhoneInput } from "../../common/phoneInput";

export const MerchantPrincipalDetailsTab: React.FC<
  IMerchantPrincipalDetailsFormPart
> = ({ countries, validator, principalData, index }) => {
  const dob = moment(principalData.dateOfBirth, true);
  return (
    <Row gutter={24}>
      <Col span={16}>
        <AntForm.Item
          rules={[validator]}
          name={["merchantPrincipalData", "principals", index, "firstName"]}
          label={"First Name"}
          initialValue={principalData.firstName}
        >
          <Input />
        </AntForm.Item>
        <AntForm.Item
          rules={[validator]}
          name={["merchantPrincipalData", "principals", index, "lastName"]}
          label={"Last Name"}
          initialValue={principalData.lastName}
        >
          <Input />
        </AntForm.Item>
        <AntForm.Item
          rules={[validator]}
          label={"Date of Birth"}
          labelCol={{ span: 24 }}
          name={["merchantPrincipalData", "principals", index, "dateOfBirth"]}
          id={`merchantPrincipalData.principals.${index}.dateOfBirth`}
          initialValue={dob}
        >
          {/*@ts-ignore*/}
          <DatePicker format="DD/MM/YYYY" style={{ width: 160 }} />
        </AntForm.Item>
        <AntForm.Item
          rules={[validator]}
          name={["merchantPrincipalData", "principals", index, "addressLine1"]}
          label={"Address Line 1"}
          initialValue={principalData.addressLine1}
        >
          <Input />
        </AntForm.Item>
        <AntForm.Item
          rules={[validator]}
          name={["merchantPrincipalData", "principals", index, "addressLine2"]}
          label={"Address Line 2"}
          initialValue={principalData.addressLine2}
        >
          <Input />
        </AntForm.Item>
        <AntForm.Item
          rules={[validator]}
          name={["merchantPrincipalData", "principals", index, "city"]}
          label={"City"}
          initialValue={principalData.city}
        >
          <Input />
        </AntForm.Item>
        <AntForm.Item
          rules={[validator]}
          name={["merchantPrincipalData", "principals", index, "country"]}
          label={"Country"}
          initialValue={principalData.country}
        >
          <Select
            showSearch
            optionFilterProp="label"
            placeholder="Country"
            options={countries.map((item) => ({
              value: item.name,
              label: item.name,
            }))}
          ></Select>
        </AntForm.Item>
        <AntForm.Item
          rules={[validator]}
          name={["merchantPrincipalData", "principals", index, "postCode"]}
          label={"Post Code"}
          initialValue={principalData.postCode}
        >
          <Input />
        </AntForm.Item>
        <AntForm.Item
          rules={[validator]}
          name={["merchantPrincipalData", "principals", index, "phoneNumber"]}
          label="Phone Number"
          initialValue={principalData.phoneNumber}
        >
          <PhoneInput country={"gb"} />
        </AntForm.Item>
        <AntForm.Item
          rules={[validator]}
          name={["merchantPrincipalData", "principals", index, "email"]}
          label={"Email"}
          initialValue={principalData.email}
        >
          <Input />
        </AntForm.Item>
        <AntForm.Item
          rules={[validator]}
          name={["merchantPrincipalData", "principals", index, "ownership"]}
          label={"Ownership"}
          initialValue={principalData.ownership}
        >
          <InputNumber />
        </AntForm.Item>
      </Col>
    </Row>
  );
};

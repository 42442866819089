import { useEffect, useState } from "react";
import { Button, Form as AntForm, Tabs } from "antd";
import moment from "moment";
import { SaveOutlined } from "@ant-design/icons";
import { MerchantDetailsTab } from "./details";
import { MerchantContactsTab } from "./contacts";
import { MerchantSettlementsTab } from "./merchantSettlements";
import { MerchantRegistrationDetailsTab } from "./registrationDetails";
import { IMerchantForm, IMerchantDetail, IPrincipalData } from "./types";
import { MerchantOfficeDetailsTab } from "./officeDetails";
import { MerchantPrincipalDetailsTab } from "./principalDetails";
import { Styled } from "./styled";
import { FIELD_TAB_MAPPING, yupValidator } from "../../common/merchantForm";
import "./style.css";
import { MerchantIntegrationDetailsTab } from "./integrationDetails";
import { MerchantPricingModelsTab } from "./pricingModels";
import { NamePath, StoreValue } from "antd/lib/form/interface";
import { ValidationError } from "yup";

type TargetKey = React.MouseEvent | React.KeyboardEvent | string;
type PrincipalsFieldData = {
  name: NamePath;
  value: StoreValue;
};
type TabsItem = {
  label: string;
  children: JSX.Element;
  key: string;
  closable?: boolean;
  disabled?: boolean;
};

const NUMBERS_REGEXP = new RegExp("\\d+");
const DEFAULT_INITIAL_ITEM_KEY = "details";
const PRINCIPALS_KEY_PREFIX = "principals";

const getDefaultPrincipal = (): IPrincipalData => ({
  firstName: "",
  lastName: "",
  dateOfBirth: new Date(),
  addressLine1: "",
  addressLine2: "",
  city: "",
  country: "",
  postCode: "",
  phoneNumber: "",
  email: "",
  ownership: 0,
});

export const Form: React.FC<IMerchantForm> = ({
  type,
  form,
  merchantSchema,
  dictionaryData,
  dictionaryMccData,
  partners,
  onProcessMerchant,
  initialData,
}) => {
  const [isButtonEnabled, setButtonState] = useState<boolean>(type === "edit");
  const [isSameAddress, setSameAddress] = useState<boolean>(false);
  const [principals, setPrincipals] = useState<IPrincipalData[]>(
    initialData?.merchantPrincipalData?.principals || []
  );
  const [activeKey, setActiveKey] = useState(DEFAULT_INITIAL_ITEM_KEY);

  const merchantValidator = yupValidator(
    merchantSchema,
    form.getFieldsValue,
    setButtonState,
    setActiveKey,
    type
  );

  const tabItems: TabsItem[] = [
    {
      label: "Details",
      children: (
        <MerchantDetailsTab
          countries={dictionaryData.countries}
          timezones={dictionaryData.timeZones}
          currencies={dictionaryData.currencies}
          mccList={dictionaryMccData.mccList}
          partners={partners}
          validator={merchantValidator}
          initialData={initialData}
        />
      ),
      key: "details",
      closable: false,
    },
    {
      label: "Integration Details",
      children: (
        <MerchantIntegrationDetailsTab
          validator={merchantValidator}
          initialData={initialData}
        />
      ),
      key: "integrationDetails",
      closable: false,
    },
    {
      label: "Registration Details",
      children: (
        <MerchantRegistrationDetailsTab
          type={type}
          countries={dictionaryData.countries}
          validator={merchantValidator}
          isAddressSameAsRegistration={isSameAddress}
          setSameRegistrationAddress={setSameAddress}
          initialData={initialData?.registrationAddressData}
        />
      ),
      key: "registrationAddress",
      closable: false,
    },
    {
      label: "Office Details",
      children: (
        <MerchantOfficeDetailsTab
          countries={dictionaryData.countries}
          validator={merchantValidator}
          isAddressSameAsRegistration={false}
          initialData={initialData?.officeAddressData}
        />
      ),
      key: "officeAddress",
      closable: false,
    },
    {
      label: "Contacts",
      children: (
        <MerchantContactsTab
          validator={merchantValidator}
          initialData={initialData?.merchantContactData}
        />
      ),
      key: "contacts",
      closable: false,
    },
    {
      label: "Merchant Settlements",
      children: (
        <MerchantSettlementsTab
          validator={merchantValidator}
          initialData={initialData?.settlementDetailsData}
        />
      ),
      key: "settlement",
      closable: false,
    },
    {
      label: "Merchant Pricing Models",
      children: (
        <MerchantPricingModelsTab
          validator={merchantValidator}
          initialData={initialData}
        />
      ),
      key: "pricingModels",
      closable: false,
    },
  ];

  const tabItemsWithPrincipals: TabsItem[] = [];
  principals.forEach((principal, index) => {
    tabItemsWithPrincipals.push({
      label: `Principal Details ${index + 1}`,
      children: (
        <MerchantPrincipalDetailsTab
          countries={dictionaryData.countries}
          validator={merchantValidator}
          principalData={principal}
          index={index}
        />
      ),
      key: `${PRINCIPALS_KEY_PREFIX}${index}`,
      closable: true,
    });
  });

  const [items, setItems] = useState([...tabItems, ...tabItemsWithPrincipals]);
  const onChange = (newActiveKey: string) => {
    setActiveKey(newActiveKey);
  };

  const collectTabsWithPrincipals = (
    newPrincipals: IPrincipalData[]
  ): TabsItem[] => {
    const newPanes: TabsItem[] = tabItems.map((item) => item);
    newPrincipals.forEach((principal, index) => {
      newPanes.push({
        label: `Principal Details ${index + 1}`,
        children: (
          <MerchantPrincipalDetailsTab
            countries={dictionaryData.countries}
            validator={merchantValidator}
            principalData={principal}
            index={index}
          />
        ),
        key: `${PRINCIPALS_KEY_PREFIX}${index}`,
        closable: true,
      });
    });
    return newPanes;
  };

  const add = () => {
    const newPrincipals: IPrincipalData[] = [];
    const data = form.getFieldsValue([["merchantPrincipalData", "principals"]]);
    principals.forEach((_, i) =>
      newPrincipals.push(data.merchantPrincipalData.principals[i])
    );
    newPrincipals.push(getDefaultPrincipal());
    setPrincipals(newPrincipals);
    const newPanes: TabsItem[] = collectTabsWithPrincipals(newPrincipals);
    setItems(newPanes);
  };

  const remove = (targetKey: TargetKey) => {
    const index = Number(
      targetKey.toString().replace(PRINCIPALS_KEY_PREFIX, "")
    );
    const newPrincipals: IPrincipalData[] = [];
    const data = form.getFieldsValue([["merchantPrincipalData", "principals"]]);
    principals.forEach((_, i) => {
      if (i !== index) {
        newPrincipals.push(data.merchantPrincipalData.principals[i]);
      }
    });
    setPrincipals(newPrincipals);
    const newPanes: TabsItem[] = collectTabsWithPrincipals(newPrincipals);
    setItems(newPanes);
  };

  const onEdit = (
    targetKey: React.MouseEvent | React.KeyboardEvent | string,
    action: "add" | "remove"
  ) => {
    if (action === "add") {
      add();
    } else {
      remove(targetKey);
    }
  };

  const onFormSubmit = async (data: IMerchantDetail) => {
    const formData = form.getFieldsValue([
      ["merchantPrincipalData", "principals"],
    ]);
    principals.forEach((_, i) => {
      data.merchantPrincipalData.principals[i] =
        formData.merchantPrincipalData.principals[i];
    });

    if (isSameAddress && type === "create") {
      data.officeAddressData = data.registrationAddressData;
    }

    let processData = data;
    if (initialData && type === "edit") {
      processData = {
        merchantName: data.merchantName
          ? data.merchantName
          : initialData.merchantName,
        merchantNumber: data.merchantNumber
          ? data.merchantNumber
          : initialData.merchantNumber,
        dba: data.dba ? data.dba : initialData.dba,
        kind: data.kind ? data.kind : initialData.kind,
        mcc: data.mcc ? data.mcc : initialData.mcc,
        timeZone: data.timeZone ? data.timeZone : initialData.timeZone,
        cctExternalId: data.cctExternalId
          ? data.cctExternalId
          : initialData.cctExternalId,
        cdxExternalId: data.cdxExternalId
          ? data.cdxExternalId
          : initialData.cdxExternalId,
        fsrvExternalId: data.fsrvExternalId
          ? data.fsrvExternalId
          : initialData.fsrvExternalId,
        vatID: data.vatID ? data.vatID : initialData.vatID,
        onboardingDetailsTotalSales: data.onboardingDetailsTotalSales
          ? data.onboardingDetailsTotalSales
          : initialData.onboardingDetailsTotalSales,
        onboardingDetailsProductServiceSold:
          data.onboardingDetailsProductServiceSold
            ? data.onboardingDetailsProductServiceSold
            : initialData.onboardingDetailsProductServiceSold,
        tidPrefix: data.tidPrefix ? data.tidPrefix : initialData.tidPrefix,
        country: data.country ? data.country : initialData.country,
        currency: data.currency ? data.currency : initialData.currency,
        feePercent:
          data.feePercent !== null && data.feePercent !== undefined
            ? data.feePercent
            : initialData.feePercent,
        mid: data.mid ? data.mid : initialData.mid,
        originType: data.originType ? data.originType : initialData.originType,
        status: data.status ? data.status : initialData.status,
        settlementDetailsData: data.settlementDetailsData
          ? data.settlementDetailsData
          : initialData.settlementDetailsData,
        registrationAddressData: data.registrationAddressData
          ? data.registrationAddressData
          : initialData.registrationAddressData,
        officeAddressData: data.officeAddressData
          ? data.officeAddressData
          : initialData.officeAddressData,
        merchantContactData: data.merchantContactData
          ? data.merchantContactData
          : initialData.merchantContactData,
        merchantPrincipalData: data.merchantPrincipalData
          ? data.merchantPrincipalData
          : initialData.merchantPrincipalData,
        needExportData: data.needExportData
          ? data.needExportData
          : initialData.needExportData,
        partner:
          data.partner && data.partner.id
            ? data.partner
            : initialData.partner
            ? initialData.partner
            : undefined,
      };
    }

    try {
      await merchantSchema.validate(processData);
    } catch (err: unknown) {
      if (err instanceof ValidationError) {
        const field = err.path;
        if (typeof field === "string") {
          const fieldNames = field.split(".");
          if (fieldNames && fieldNames.length && typeof FIELD_TAB_MAPPING[fieldNames[0]] === "string") {
            let tabKey = FIELD_TAB_MAPPING[fieldNames[0]];
            if (fieldNames.length >= 2) {
              const numbers = fieldNames[1].match(NUMBERS_REGEXP);
              if (numbers && !Number.isNaN(numbers[0])) {
                tabKey += `${numbers[0]}`
              }
              
            }
            setActiveKey(tabKey);
          }
        }
      }
      console.error(err);
      return;
    }
    onProcessMerchant(processData);
  };

  useEffect(() => {
    setActiveKey(items[items.length - 1].key);
    const data: PrincipalsFieldData[] = [];
    principals.forEach((principal, index) => {
      data.push({
        name: ["merchantPrincipalData", "principals", index, "firstName"],
        value: principal.firstName,
      });
      data.push({
        name: ["merchantPrincipalData", "principals", index, "lastName"],
        value: principal.lastName,
      });
      data.push({
        name: ["merchantPrincipalData", "principals", index, "dateOfBirth"],
        value: moment(principal.dateOfBirth),
      });
      data.push({
        name: ["merchantPrincipalData", "principals", index, "addressLine1"],
        value: principal.addressLine1,
      });
      data.push({
        name: ["merchantPrincipalData", "principals", index, "addressLine2"],
        value: principal.addressLine2,
      });
      data.push({
        name: ["merchantPrincipalData", "principals", index, "city"],
        value: principal.city,
      });
      data.push({
        name: ["merchantPrincipalData", "principals", index, "country"],
        value: principal.country,
      });
      data.push({
        name: ["merchantPrincipalData", "principals", index, "postCode"],
        value: principal.postCode,
      });
      data.push({
        name: ["merchantPrincipalData", "principals", index, "email"],
        value: principal.email,
      });
      data.push({
        name: ["merchantPrincipalData", "principals", index, "phoneNumber"],
        value: principal.phoneNumber,
      });
      data.push({
        name: ["merchantPrincipalData", "principals", index, "ownership"],
        value: principal.ownership,
      });
    });
    form.setFields(data);
  }, [items, form, principals]);

  return (
    <>
      <AntForm
        form={form}
        layout="vertical"
        scrollToFirstError={true}
        onFinish={(values) => onFormSubmit(values)}
        className="merchant-form"
      >
        <Styled.ButtonWrapper>
          <Button
            type="primary"
            htmlType={"submit"}
            size="large"
            icon={<SaveOutlined />}
            disabled={!isButtonEnabled}
          >
            save
          </Button>
        </Styled.ButtonWrapper>
        <Tabs
          type="editable-card"
          tabPosition="left"
          onChange={onChange}
          activeKey={activeKey}
          onEdit={onEdit}
          items={items.map((item) => {
            return {
              ...item,
              disabled: item.key === "officeAddress" && isSameAddress,
            };
          })}
          hideAdd={items.length >= 11}
        />
      </AntForm>
    </>
  );
};

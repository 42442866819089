import { Form as AntForm, Space, Select, Switch, Input, Row, Col } from "antd";
import { IMerchantRegistrationDetailsFormPart } from "./types";
import { Styled } from "./styled";

export const MerchantRegistrationDetailsTab: React.FC<
  IMerchantRegistrationDetailsFormPart
> = ({
  type,
  countries,
  validator,
  isAddressSameAsRegistration,
  setSameRegistrationAddress,
  initialData,
}) => {
  return (
    <Row gutter={24}>
      <Col span={16}>
        <AntForm.Item
          rules={[validator]}
          name={["registrationAddressData", "addressLine1"]}
          id={"registrationAddressLine1"}
          label={"Address Line 1"}
          initialValue={initialData?.addressLine1}
        >
          <Input />
        </AntForm.Item>
        <AntForm.Item
          rules={[validator]}
          name={["registrationAddressData", "addressLine2"]}
          id={"registrationAddressLine2"}
          label={"Address Line 2"}
          initialValue={initialData?.addressLine2}
        >
          <Input />
        </AntForm.Item>
        <AntForm.Item
          rules={[validator]}
          name={["registrationAddressData", "country"]}
          id={"registrationCountryId"}
          label={"Country"}
          initialValue={initialData?.country}
        >
          <Select
              showSearch
              optionFilterProp="label"
              placeholder="Country"
              options={countries?.map((item) => ({
                value: item.name,
                label: item.name,
              }))}
          ></Select>
        </AntForm.Item>
        <AntForm.Item
            rules={[validator]}
            name={["registrationAddressData", "county"]}
            id={"registrationAddressCounty"}
            label={"County"}
            initialValue={initialData?.county}
        >
          <Input />
        </AntForm.Item>
        <AntForm.Item
          rules={[validator]}
          name={["registrationAddressData", "city"]}
          id={"registrationCity"}
          label={"City"}
          initialValue={initialData?.city}
        >
          <Input />
        </AntForm.Item>
        <AntForm.Item
          rules={[validator]}
          name={["registrationAddressData", "state"]}
          id={"registrationState"}
          label={"State"}
          initialValue={initialData?.state}
        >
          <Input />
        </AntForm.Item>
        <AntForm.Item
          rules={[validator]}
          name={["registrationAddressData", "postCode"]}
          id={"registrationPostCode"}
          label={"Post Code"}
          initialValue={initialData?.postCode}
        >
          <Input />
        </AntForm.Item>
        <AntForm.Item
          rules={[validator]}
          name={["registrationAddressData", "postTown"]}
          id={"registrationPostTown"}
          label={"Post Town"}
          initialValue={initialData?.postTown}
        >
          <Input />
        </AntForm.Item>
        {type === "create" ? (
          <Space direction="vertical">
            <Styled.TextWrapper>Is office address same</Styled.TextWrapper>
            <Switch
              defaultChecked={isAddressSameAsRegistration}
              onChange={(value) => setSameRegistrationAddress(value)}
            />
            <Row></Row>
            <Row></Row>
          </Space>
        ): <></>}
      </Col>
    </Row>
  );
};
